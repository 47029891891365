import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import m3eLogo from "../../media/images/m3eThgLogo.png";
import m3eLogoGreen from "../../media/images/THG-QuotenGreen.svg";
import m3eLogoWhite from "../../media/images/THG-QuotenWhite.svg";
import bild from "../../media/images/Bild.svg";

const Hero = () => {
  const scrollSmoothly = (section) => {
    var goTo = document.getElementById(section).offsetTop;
    window.scrollTo({
      top: goTo,
      behavior: "smooth",
    });
  };
  return (
    <div className="relative overflow-hidden bg-gradient-to-tr from-darkGreen via-lightestGreen to-lightGreen">
      <div className="relative pt-6 pb-16 sm:pb-24">
        <Popover>
          <nav
            className="relative mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6"
            aria-label="Global"
          >
            <div className="flex flex-1 items-center">
              <div className="flex w-full items-center">
                <a href="#" className="flex-none ">
                  <span className="sr-only">Your Company</span>
                  <a href="https://www.thgquoten.com/">
                    <img
                      className="h-8 w-auto sm:h-10"
                      src={m3eLogoWhite}
                      alt=""
                    />
                  </a>
                </a>
                <div className="-mr-2 grow flex items-center justify-end md:hidden"></div>
                <div className="hidden grow space-x-10 md:ml-10 md:flex justify-center"></div>
              </div>
            </div>
          </nav>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
            >
              <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                <div className="flex items-center justify-between px-5 pt-4">
                  <div>
                    <a href="https://www.thgquoten.com/">
                      <img className="h-8 w-auto" src={m3eLogoGreen} alt="" />
                    </a>
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lightGreen">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon
                        className="h-6 w-6 text-lightGreen"
                        aria-hidden="true"
                      />
                    </Popover.Button>
                  </div>
                </div>
                <div className="space-y-1 px-4 pt-2 pb-3 flex flex-col"></div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>

        <main className="mt-16 sm:mt-24">
          <div className="mx-auto max-w-7xl">
            <div className="lg:grid lg:grid-cols-12 lg:gap-2">
              <div className="px-4 sm:px-6 sm:text-center md:mx-auto md:max-w-3xl lg:col-span-7 lg:flex lg:items-center lg:text-left">
                <div>
                  <p className="text-[#6EC846] font-[400] leading-[60%] sm:leading-[120%] text-[1.5rem] md:text-[2rem]">
                    Wallbox-Aktion
                  </p>
                  <h1 className="mt-4 text-[3.5rem] font-[700] leading-[65px] md:leading-[88px] tracking-tight text-[#FFFFFF] sm:text-3xl md:text-6xl">
                    Wieder zurück ab November 2023
                  </h1>
                  <br />
                  <br />
                  <div
                    class="bg-yellow-100 rounded-lg py-5 px-6 mb-3 text-base text-yellow-700 inline-flex items-center w-full"
                    role="alert"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="exclamation-triangle"
                      class="w-24 h-24 mr-6 fill-current"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                      ></path>
                    </svg>
                    <p class="md:leading-[120%]  sm:mt-5 text-xl lg:text-[2rem]">
                      {" "}
                      Vielen Dank für das Interesse und die rege Teilnahme an
                      dieser Aktion. Nach dem Vermarktungsende der Quoten für
                      2022 ist die Aktion nun vorerst beendet.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-16 sm:mt-24 lg:col-span-5 lg:mt-0">
                <div className="sm:mx-auto sm:w-full sm:max-w-md sm:overflow-hidden">
                  <div className="px-4 py-8 sm:px-10">
                    <div>
                      <img src={bild} alt="wall box image" className="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default Hero;
