import React from "react";
import Footer from "../components/authenticated/nav/Footer";
//disabled the wallbox offer
/* import {
  Hero,
  About,
  Features,
  FAQ,
  WallBoxInstallation,
  Portfolio,
} from "../components/wallbox"; */
import DisabledAlert from "../components/wallbox/DisabledAlert";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const wallbox = () => {
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  return (
    <div>
      <DisabledAlert />
      {/* disabled the wallbox offer */}
      {/*  <Hero />
      <About />
      <Features />
      <Portfolio />
      <FAQ />
      <WallBoxInstallation /> */}
      <Footer />
    </div>
  );
};

export default wallbox;
